import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(public readonly analytics: AngularFireAnalytics) {}

  contactFormOpened(source?: string): void {
    const eventData = { value: 1, currency: 'USD', ...(source && { source }) };
    this.sendEvent('contact_form_open', eventData);
  }

  contactFormSubmitted(source?: string): void {
    const eventData = { value: 100, currency: 'USD', ...(source && { source }) };
    this.sendEvent('contact_form_submission', eventData);
  }

  private sendEvent(name: string, data: Record<string, string | number>): void {
    this.analytics.logEvent(name, data).catch(error => console.error(error));
  }
}
